<template>
  <v-container fluid>
    <div class="mx-10 mt-2">
      <!-- <v-row justify="space-between">
            <v-col cols="2">
                dfjlkasjdlkf
            </v-col>
            v
        </v-row> -->
      <v-row>
        <v-col cols="3">
          <v-text-field type="search" v-model="searchUsuario" label="Búscar usuario" append-icon="mdi-account"
            autocomplete="false" class="pr-6"></v-text-field>
        </v-col>

        <v-col cols="1">
          <v-btn @click="mainUsuarios()" color="#031D6A" class="white--text mt-3">
            Buscar
            <v-icon right dark> mdi-magnify </v-icon>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <div class="text-end">
            <v-btn color="green" class="white--text mt-3 px-5" @click="fnCrearNuevoUsuario()">
              CREAR NUEVO USUARIO
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- /**
    *
    * tabla de usuarios
    * 
    */ -->
    <v-data-table :headers="headers" :items="tbl_usuarios" class="elevation-4 mt-10 mx-2" hide-default-footer
      disable-pagination :options.sync="sector_options">
      <!-- botones/íconos para las configuraciones -->
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ fnGetDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon title="eliminar usuario" class="align-center mr-2" size="19" color="black" @click="fnDarDeBaja(item)">
          mdi-delete
        </v-icon>

        <v-icon class="ver configuraciones" title="Configuraciones" size="19" color="black"
          @click="fnOpenDialog_EditarUsuario(item)">
          mdi-cog
        </v-icon>
        <v-icon class="align-center ml-2 mt-1" title="Historial de cambios de contraseña" size="22" color="black"
          @click="fnVerRegistros(item)">
          mdi-math-log
        </v-icon>
      </template>
    </v-data-table>
    <!-- PAGINACION -->
    <v-col cols="12">
      <v-col offset="9" cols="3">
        <v-select v-model="usuario_pageSize" :items="usuario_pageSizes" label="Registros por página"
          @change="usuario_handlePageSizeChange"></v-select>
      </v-col>
      <v-col class="">
        Total de registros: {{ usuario_totalRegistros }}
        <v-pagination class="float-right" v-model="usuario_page" :length="usuario_totalPages" total-visible="15"
          next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="usuario_handlePageChange"></v-pagination>
      </v-col>
    </v-col>
    <!-- FIN PAGINACION -->
    <!-- /** */ -->
    <!-- Formulario para la creación de usuarios -->
    <v-dialog v-model="dialogCrearUsuario" max-width="1300" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 font-weight-bold">Formulario para creación de usuarios</v-card-title>

        <v-container fluid>
          <v-row>
            <v-col cols="7">
              <v-card min-height="450" flat>
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step step="1" color="#031D6A" :complete="e1 > 1">
                      Datos generales
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="2" color="#031D6A" :complete="e1 > 2">
                      Módulos
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" color="#031D6A" :complete="e1 > 3">
                      Países
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4" color="#031D6A">
                      Asignación
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <!-- FORMULARIO PARA INGRESO DE CREDENCIALES DEL NUEVO USUARIO -->
                    <v-stepper-content step="1" class="mt-n3">
                      <v-card flat elevation="0" min-height="360">
                        Datos de la persona:

                        <v-row class="mb-1">
                          <v-col cols="12">
                            <v-text-field class="pl-3" :ref="nuevoUsuario.nombreYapellido"
                              v-model="nuevoUsuario.nombreYapellido" label="Nombres y apellidos" required
                              :rules="[rules.required]"></v-text-field>
                          </v-col>
                        </v-row>
                        Credenciales para inicio de sesión:
                        <v-row no-gutters>
                          <v-col cols="6">
                            <v-text-field @keydown.space.prevent class="pr-3" :ref="nuevoUsuario.usuario"
                              v-model="nuevoUsuario.usuario" label="Usuario" required prepend-icon="mdi-account"
                              :rules="[rules.required]" autocomplete="new-password"></v-text-field>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field class="pl-3" :ref="nuevoUsuario.password" v-model="nuevoUsuario.password"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]"
                              :type="show ? 'text' : 'password'" label="Contraseña" required prepend-icon="mdi-lock"
                              persistent-hint hint="" @click:append="show = !show"
                              autocomplete="new-password"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-switch v-model="switch_es_super_usuario" class="ml-3">
                          <template v-slot:label>
                            <span v-if="switch_es_super_usuario == 1">
                              Super usuario (sin restricciones)
                            </span>
                            <span v-else>
                              Usuario administrativo ( con accesos restringidos)
                            </span>
                          </template>
                        </v-switch>
                      </v-card>
                      <!-- FIN FORMULARIO CREDENCIALES -->
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card flat min-height="352" elevation="0">
                        <v-card-text>
                          <v-row no-gutters>
                            <v-col :md="switch_es_super_usuario ? 9 : 12" v-if="switch_es_super_usuario != 1">
                              <div v-for="(
                                  section, index
                                ) in listado_de_persmisos_para_modulos" :key="index">
                                <strong>{{ section.title }}</strong>
                                <v-checkbox v-for="option in section.options" :key="option.value" :label="option.label"
                                  :disabled="option.disabled ? true : false" v-model="permisos_modulos"
                                  :value="option.value" class="mt-n1"></v-checkbox>
                              </div>
                            </v-col>
                            <v-col md="9" v-else>
                              <p>usuario sin restricciones</p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <!-- PARA LA ASIGNACIÓN DE PAÍSES -->
                    <v-stepper-content step="3">
                      <v-card flat elevation="0" min-height="352">
                        <div class="mt-2">
                          <v-row justify="center">
                            <v-col cols="9">
                              <v-row v-for="pais in paises" v-bind:key="pais.id">
                                <v-col cols="auto" class="mr-auto">
                                  <p class="text--secondary">
                                    {{ pais.nombre }}
                                  </p>
                                </v-col>
                                <v-col cols="auto" v-if="permisos_paises_asignados">
                                  <v-checkbox v-model="permisos_paises_asignados" :value="pais.id"
                                    class="mt-n1"></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                      <v-card flat elevation="0" min-height="340">
                        <v-card-text>
                          <v-text-field v-model="searchSector" label="Búscar" clearable></v-text-field>
                          <span v-if="
                            permisos_paises_asignados == [] ||
                            !permisos_paises_asignados
                          ">No hay pais asignado al usuario</span>
                          <!-- 
                          *
                          *  tabla para asignacion de sectores para creacion / edicion de usuarios  
                          *
                          *-->
                          <v-data-table v-model="permisos_usuarios_asignados" :headers="headers_asignacion_de_sectores"
                            :items="sectores" show-select class="elevation-1" hide-default-footer disable-pagination
                            :options.sync="sector_options">
                          </v-data-table>
                          <!-- PAGINACION -->
                          <v-col cols="12">
                            <v-col offset="9" cols="3">
                              <v-select v-model="sector_pageSize" :items="sector_pageSizes" label="Registros por página"
                                @change="sector_handlePageSizeChange"></v-select>
                            </v-col>
                            <v-col class="">
                              Total de registros: {{ sector_totalRegistros }}
                              <v-pagination class="float-right" v-model="sector_page" :length="sector_totalPages"
                                total-visible="15" next-icon="mdi-menu-right" prev-icon="mdi-menu-left"
                                @input="sector_handlePageChange"></v-pagination>
                            </v-col>
                          </v-col>
                          <!-- FIN PAGINACION -->
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card>
            </v-col>

            <!-- Columna derecha para mostrar el RESUMEN DEL NUEVO USUARIO -->
            <v-col cols="5">
              <v-card min-height="460">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <p class="text-h6">Resumen de nuevo usuario</p>
                      <p class="text-body-2 text--secondary mt-n2">
                        Nombre: {{ nuevoUsuario.nombreYapellido }}
                      </p>
                      <p class="text-body-2 text--secondary">
                        Usuario: {{ nuevoUsuario.usuario }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      Módulos:
                      <ul v-if="getSelectedPermissions().length > 0">
                        <li v-for="(
                            permission, index
                          ) in getSelectedPermissions()" :key="index">
                          <strong>{{ permission.section }}:</strong>
                          {{ permission.label }}
                        </li>
                      </ul>
                      <p v-else>No hay permisos seleccionados.</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      Asignaciones:
                      <ul v-if="getSelectedAsignaciones().length > 0">
                        <li v-for="item in getSelectedAsignaciones()" :key="item.id">
                          <strong>País: {{ item.pais.nombre }} - Sector
                            {{ item.nombre }}</strong>
                          <!-- <ul>
                          <li>
                            País: {{ item.pais.nombre }} (ID:
                            {{ item.pais.id }})
                          </li>
                          <li>
                            División: {{ item.division.nombre }} (ID:
                            {{ item.division.id }})
                          </li>
                          <li>
                            Región: {{ item.region.nombre }} (ID:
                            {{ item.region.id }})
                          </li>
                          <li>Fecha de Creación: {{ item.fechaCreacion }}</li>
                          <li>
                            Fecha de Actualización:
                            {{ item.fechaActualizacion }}
                          </li>
                        </ul> -->
                        </li>
                      </ul>
                      <p v-else>No hay datos de sectores disponibles.</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-btn v-if="switch_es_super_usuario == 1" :disabled="e1 > 3" color="#031D6A" class="white--text mb-2"
            @click="fnNextStep()">
            siguiente
          </v-btn>
          <v-btn v-else :disabled="e1 > 3 ||
            (e1 == 3 && permisos_paises_asignados.length == 0) ||
            (e1 == 2 && permisos_modulos.length == 0)
            " color="#031D6A" class="white--text mb-2" @click="fnNextStep()">
            siguiente
          </v-btn>

          <v-btn class="ml-4 mb-2" :disabled="e1 == 1" text @click="fnBackStep()">
            Atras
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn text class="text--secondary mr-6 mb-2" @click="
            (dialogCrearUsuario = false), (flagEditar = false), (e1 = 1)
            ">CANCELAR</v-btn>
          <v-btn v-if="!flagEditar" small color="green" class="white--text px-3 mb-2"
            @click="fnCrearUsuarioConfirm()">CREAR</v-btn>
          <v-btn v-else small color="green" class="white--text px-3 mb-2"
            @click="fnEditarUsuarioConfirm()">EDITAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventanas de diálogos para mostrar en los botones de dar de baja, configurar y log -->

    <!-- ventana para dar de baja -->
    <v-dialog v-model="dialogDarDeBaja" max-width="50%">
      <v-card>
        <v-card-title>¿Esta seguro de dar de baja al usuario
          {{ datosUsuarios.nombreApellido }} ({{
            datosUsuarios.user
          }})?</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <p class="mt-1">
            El proceso de dar de baja a un usuario es irreversible.
          </p>
          <ul>
            <li>
              El usuario ya no tendrá acceso a la información dentro del panel
            </li>
            <li>El usuario no se podrá volver a dar de alta</li>
            <li>El usuario no se eliminará de la base de datos</li>
            <li>Todas las opciones de configuración se desabilitarán</li>
          </ul>
          <v-checkbox label="De acuerdo" value="value" v-model="deAcuerdo_darDeBaja"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text dark @click="dialogDarDeBaja = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 " text :disabled="!deAcuerdo_darDeBaja" @click="fnConfirmarDarDeBaja()">
            Si, Dar de baja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana para el historico de cambio de contraseñas -->
    <v-dialog v-model="dialogHistorico" max-width="95%" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2">LOG - histórico cambio de contraseñas</v-card-title>
        <v-divider></v-divider>
        <v-container>
          <tablaCambiosHistorico :prop_ver_log_de_usuario_id="ver_log_de_usuario_id"
            :prop_dialogHistorico="dialogHistorico"></tablaCambiosHistorico>
        </v-container>

        <v-card-actions class="justify-end pb-4 pr-7">
          <v-btn text class="text--secondary" @click="dialogHistorico = false">CANCELAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import tablaCambiosHistorico from '../agentesDeComercio/tablaCmabioshistorico.vue';
export default {
  name: 'usuariosAdministrativos',
  components: { tablaCambiosHistorico },
  data() {
    return {
      ver_log_de_usuario_id: null,
      //
      flagEditar: false,
      prop_existing_user: null,
      //
      searchSector: null,
      searchUsuario: null,
      //pagination
      usuario_options: {},
      usuario_page: 1,
      usuario_totalPages: 0,
      usuario_pageSize: 10,
      usuario_pageSizes: [10, 25, 50, 100],
      usuario_totalRegistros: 0,
      //pagination
      sector_options: {},
      sector_page: 1,
      sector_totalPages: 0,
      sector_pageSize: 10,
      sector_pageSizes: [10, 25, 50, 100],
      sector_totalRegistros: 0,
      //pagination fin
      /**permisos a usuario dashboard */
      permisos_modulos: [],
      permisos_paises_asignados: [],
      permisos_usuarios_asignados: [],
      //
      switch_es_super_usuario: false,

      listado_de_persmisos_para_modulos: [
        {
          title: 'Gestiones por etapas',
          options: [
            {
              label: 'Precalificaciones incompletas',
              value: '/precalificaciones-incompletas',
            },
            {
              label: 'Precalificaciones Completadas',
              value: '/precalificaciones-completadas',
            },
            { label: 'Gestiones En Revisión', value: '/gestiones-en-revision' },
            {
              label: 'Gestiones Con Pendientes',
              value: '/gestiones-con-pendientes',
            },
            {
              label: 'Gestiones ConRectificaciones',
              value: '/gestiones-con-rectificaciones',
            },
            {
              label: 'Gestiones Con Compromisos de Pago',
              value: '/gestiones-con-compromiso-de-pago',
            },
            {
              label: 'Gestiones Para Aprobación',
              value: '/gestiones-listas-para-aprobacion',
            },
            { label: 'Gestiones Concluidas', value: '/gestiones-concluidas' },
            { label: 'Seguimiento de Gestiones', value: '/seguimiento-gestion' },
            {
              label: 'Documentos pendientes por compromiso de pago',
              value: '/documentos-pendientes-con-compromiso-de-pago',
            },
          ],
        },
        {
          title: 'Usuarios Administrativos',
          options: [
            { label: 'Agentes de comercio', value: '/usuarios-administrativos', disabled: 1 },
            { label: 'Crear Usuarios', value: 'UsuariosAdmonCrear', type: 'admonUsers', },
            { label: 'Editar Usuarios', value: 'UsuariosAdmonEditar', type: 'admonUsers', },
            { label: 'Dar de baja Usuarios', value: 'UsuariosAdmonEliminar', type: 'admonUsers', },
          ],
        },
        {
          title: 'Agentes de comercio',
          options: [
            { label: 'Agentes de comercio', value: '/agentes-de-comercio', disabled: 1 },
            { label: 'Crear Usuarios', value: 'UsuariosAgentCrear', type: 'comercioUsers', },
            { label: 'Editar Usuarios', value: 'UsuariosAgentEditar', type: 'comercioUsers', },
            { label: 'Dar de baja Usuarios', value: 'UsuariosAgentEliminar', type: 'comercioUsers', },
          ],
        },
        {
          title: 'Reportes',
          options: [

            { label: 'Reportes', value: '/Reportes', disabled: 1 },
            {
              label:
                'Listado de cantidad eventos por gestion sector nuevos y reingresos',
              type: 'reporte',
              value:
                'ListadoDeCantidadEventosPorGestionSectorNuevosYReingresos',
            },
            {
              label: 'Listado de contratos completos e incompletos',
              type: 'reporte',
              value: 'ListadoDeContratosCompletosEIncompletos',
            },
            {
              label: 'Listado de gestiones atrasadas segun evento',
              type: 'reporte',
              value: 'ListadoDeGestionesAtrasadasSegunEvento',
            },
            {
              label:
                'Listado de gestiones con complementos pendientes y compromiso de pago',
              type: 'reporte',
              value:
                'ListadoDeGestionesConComplementosPendientesYCompromisoDePago',
            },
            {
              label: 'Listado de gestiones por sector, color y autorizacion',
              type: 'reporte',
              value: 'ListadoDeGestionesPorSectorColorYAutorizacion',
            },
            {
              label: 'Listado de información ingresada por supervisora',
              type: 'reporte',
              value: 'ListadoDeInformacionIngresadaPorSupervisora',
            },
            {
              label: 'Listado de evaluaciones eliminadas móvil',
              type: 'reporte',
              value: 'ListadoDeEvaluacionesEliminadasMovil',
            },
            {
              label:
                'Listado de gestiones ingresadas por sector (en proceso, aprobada, denegada y cancelada)',
              type: 'reporte',
              value:
                'ListadoDeGestionesIngresadasPorSectorEnProcesoAprobadaDenegadaYCancelada',
            },
            {
              label: 'Listado de usuarios por bandeja',
              type: 'reporte',
              value: 'ListadoDeUsuariosPorBandeja',
            },
          ],
        },
      ],
      //
      paises: [],
      sectores: [],
      //
      dialogCrearUsuario: false,
      dialogDarDeBaja: false,
      dialogHistorico: false,
      deAcuerdo_darDeBaja: false, //para confirmar que si desea dar de baja a un usuario
      totalRecords: 0, // para mostrar el total de cambios que se han dado en la contraseña
      e1: 1, //para el stepper

      //
      searchUser: null, //para ingresar el usuario en el text-field

      //encabezados para la tabla
      headers: [
        { text: 'ID', align: 'start', value: 'id', width: 69, sortable: false },
        { text: 'Usuario', align: 'start', value: 'usuario', sortable: false },
        {
          text: 'Nombre y apellido',
          align: 'start',
          value: 'nombre',
          sortable: false,
        },
        { text: 'Rol', align: 'start', value: 'rol_nombre', sortable: false },
        { text: 'Países', align: 'start', value: 'paises', sortable: false },
        { text: 'Creado', align: 'start', value: 'createdAt', sortable: false },
        {
          text: 'Configuraciones',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],

      //datos para la tabla(se tiene un dato de prueba)
      tbl_usuarios: [],

      //--------------variables para los steppers

      //variable con las credenciales para un nuevo usuario
      nuevoUsuario: {
        usuario: '',
        password: '',
        nombreYapellido: '',
      },

      //--------para los checkbox
      checkboxValue: {
        precalificaciones: false,
        inicioProceso: false,
        revisionGestion: false,
        rectificaciones: false,
        compromisoPago: false,
        aprobacionCredito: false,
        seguimientoGestion: false,
        documentoPendientePago: false,
        gestionesConcluidas: false,
      },

      //encabezados para la tabla que aparece en el stepper de asignacion
      headers_asignacion_de_sectores: [
        { text: 'País', align: 'start', value: 'pais', sortable: false },
        { text: 'Sector', align: 'start', value: 'nombre', sortable: false },
        {
          text: 'División',
          align: 'start',
          value: 'division',
          sortable: false,
        },
        { text: 'Región', align: 'start', value: 'region', sortable: false },
      ],
      //datos (de prueba) tabla para asignacion
      datosStepperAsignacion: [
        {
          sector: '03',
          division: 'División 3',
          region: 'Centro Norte',
        },
        {
          sector: '04',
          division: 'División 3',
          region: 'Centro Norte',
        },
        {
          sector: '05',
          division: 'División 1',
          region: 'Centro Occidente',
        },
      ],

      selectedStepperAsignacion: [], // para almacenar lo que se seleccionó de la tabla principal

      //para mostrar o no las contraseñas
      show: false,
      rules: {
        required: (v) => !!v || 'Este campo es obligatorio',
      },

      datosUsuarios: {}, //para almacenar la info de los usuarios cuando se da clic en su respectivo botón

      headersHistorico: [
        {
          text: 'Quién actualizó',
          align: 'start',
          value: 'alias_usuario_administrativo',
          sortable: false,
        },
        {
          text: 'Fecha de actualización',
          align: 'start',
          value: 'createdAt',
          sortable: false,
        },
      ],
      //valores para mostrar en la tabla del histórico
      itemsHistorico: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    e1(newValue, oldValue) {
      if (newValue == 4) {
        this.page = 1;
        this.mainSectores();
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchSector(newValue, oldValue) {
      this.page = 1;
      this.mainSectores();
    },
    permisos_modulos: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        // Filtrar todos los elementos que tengan type 'reporte'
        const reportes = this.listado_de_persmisos_para_modulos
          .find(item => item.title === 'Reportes')?.options
          .filter(option => option.type === 'reporte')
          .map(option => option.value) || [];

        // Verificar si algún valor en permisos_modulos coincide con los valores de reportes
        const tieneReporte = newValue.some(value => reportes.includes(value));

        // Si tiene algún reporte y "Reportes" no está ya en permisos_modulos, agregarlo
        if (tieneReporte && !newValue.includes('/Reportes')) {
          this.permisos_modulos.push('/Reportes');
        }
        // Si no tiene reportes y "Reportes" está en permisos_modulos, eliminarlo
        else if (!tieneReporte && newValue.includes('/Reportes')) {
          this.permisos_modulos = this.permisos_modulos.filter(item => item !== '/Reportes');
        }

        /**
         * 
         * 
         */
        // Filtrar todos los elementos que tengan type 'admonUsers'
        const admonUsers = this.listado_de_persmisos_para_modulos
          .find(item => item.title === 'Usuarios Administrativos')?.options
          .filter(option => option.type === 'admonUsers')
          .map(option => option.value) || [];

        // Verificar si algún valor en permisos_modulos coincide con los valores de admonUsers
        const tieneAdmonUsers = newValue.some(value => admonUsers.includes(value));

        // Si tiene algún permiso de admonUsers y "/usuarios-administrativos" no está ya en permisos_modulos, agregarlo
        if (tieneAdmonUsers && !newValue.includes('/usuarios-administrativos')) {
          this.permisos_modulos.push('/usuarios-administrativos');
        }
        // Si no tiene permisos de admonUsers y "/usuarios-administrativos" está en permisos_modulos, eliminarlo
        else if (!tieneAdmonUsers && newValue.includes('/usuarios-administrativos')) {
          this.permisos_modulos = this.permisos_modulos.filter(item => item !== '/usuarios-administrativos');
        }
        /**
         * 
         * 
         * 
         * 
         */
        // Filtrar todos los elementos que tengan type 'comercioUsers'
        const comercioUsers = this.listado_de_persmisos_para_modulos
          .find(item => item.title === 'Agentes de comercio')?.options
          .filter(option => option.type === 'comercioUsers')
          .map(option => option.value) || [];

        // Verificar si algún valor en permisos_modulos coincide con los valores de comercioUsers
        const tieneComercioUsers = newValue.some(value => comercioUsers.includes(value));

        // Si tiene algún permiso de comercioUsers y "/agentes-de-comercio" no está ya en permisos_modulos, agregarlo
        if (tieneComercioUsers && !newValue.includes('/agentes-de-comercio')) {
          this.permisos_modulos.push('/agentes-de-comercio');
        }
        // Si no tiene permisos de comercioUsers y "/agentes-de-comercio" está en permisos_modulos, eliminarlo
        else if (!tieneComercioUsers && newValue.includes('/agentes-de-comercio')) {
          this.permisos_modulos = this.permisos_modulos.filter(item => item !== '/agentes-de-comercio');
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
  },
  methods: {
    //
    compararCadenas(original, modificado) {
      if (!original || !modificado) {
        return false;  // O maneja el caso según sea necesario
      }
      // Convertir las cadenas a arrays
      const arrayOriginal = JSON.parse(original);
      const arrayModificado = JSON.parse(modificado);

      let resultado = "";

      // Función para formatear el texto
      const formatearTexto = (texto) => {
        return texto
          .replace(/([A-Z])/g, ' $1') // Agrega un espacio antes de cada letra mayúscula
          .replace(/^./, (str) => str.toUpperCase()) // Capitaliza la primera letra
          .trim(); // Elimina espacios al inicio y al final
      };

      // Encontrar elementos agregados
      const agregados = arrayModificado.filter(item => !arrayOriginal.includes(item));

      // Encontrar elementos eliminados
      const eliminados = arrayOriginal.filter(item => !arrayModificado.includes(item));

      // Generar el resultado en texto
      if (agregados.length > 0) {
        resultado += "Se agregaron los siguientes permisos: ";
        resultado += agregados.map(formatearTexto).join(', ') + '. ';
      }

      if (eliminados.length > 0) {
        resultado += "Se eliminaron los siguientes permisos: ";
        resultado += eliminados.map(formatearTexto).join(', ') + '. ';
      }

      return resultado || "No se detectaron cambios.";
    },
    //
    compararArreglos(original, modificado, atributos) {

      if (!Array.isArray(original) || !Array.isArray(modificado)) {
        return false;  // O maneja el caso según sea necesario
      }
      let resultado = '';

      // Función auxiliar para convertir un objeto a una cadena basada en los atributos de interés
      const objetoACadena = (obj) => {
        return atributos.map((attr) => `${attr}: ${obj[attr]}`).join(', ');
      };

      // Función para comparar dos objetos basados en los atributos de interés
      const sonIguales = (obj1, obj2) => {
        return atributos.every((attr) => obj1[attr] === obj2[attr]);
      };

      // Encontrar elementos agregados
      const agregados = modificado.filter(
        (item) =>
          !original.some((originalItem) => sonIguales(item, originalItem))
      );

      // Encontrar elementos eliminados
      const eliminados = original.filter(
        (item) =>
          !modificado.some((modifiedItem) => sonIguales(item, modifiedItem))
      );

      // Generar el resultado en texto
      if (agregados.length > 0) {
        resultado += 'Se agregaron los siguientes elementos: ';
        resultado +=
          agregados.map((item) => objetoACadena(item)).join('; ') + '. ';
      }

      if (eliminados.length > 0) {
        resultado += 'Se eliminaron los siguientes elementos: ';
        resultado +=
          eliminados.map((item) => objetoACadena(item)).join('; ') + '. ';
      }

      return resultado || 'No se detectaron cambios.';
    },
    //
    compareObjects(obj_original, obj_bodyRequest, ignoreKeys = []) {
      const changes = [];

      for (const key in obj_bodyRequest) {
        if (ignoreKeys.includes(key)) {
          continue; // Ignorar esta clave si está en la lista de ignoreKeys
        }

        if (Object.prototype.hasOwnProperty.call(obj_original, key)) {
          const oldValue = obj_original[key];
          let nuevoValor = obj_bodyRequest[key];

          if (oldValue !== nuevoValor) {
            if (key == 'permisos_usuarios_asignados') {
              let cambios_por_permisos_de_usuarios_asignados =
                this.compararArreglos(
                  JSON.parse(oldValue),
                  JSON.parse(nuevoValor),
                  ['pais', 'division', 'region']
                );
              console.log(
                'cambios_por_permisos_de_usuarios_asignados',
                cambios_por_permisos_de_usuarios_asignados
              );

              changes.push(cambios_por_permisos_de_usuarios_asignados);
            } else if (key == 'permisos_modulos') {
              let cambios_por_permisos_modulos_asignados = this.compararCadenas(
                oldValue,
                nuevoValor
              );

              changes.push(cambios_por_permisos_modulos_asignados);
            } else {
              changes.push(
                `El valor de '${key}' cambió de '${oldValue}' a '${nuevoValor}'.`
              );
            }
          }
        } else {
          if (key == 'password') {
            changes.push(`El valor de '${key}' cambió.`);
          } else {
            changes.push(
              `Se agregó el campo '${key}' con valor '${obj_bodyRequest[key]}'.`
            );
          }
        }
      }

      return changes.length > 0
        ? changes.join('\n')
        : 'No se detectaron cambios.';
    },
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    /**paginacion */
    sector_getParams() {
      const params = {};
      /**paginacion */
      params['$limit'] = this.sector_pageSize;
      params['$skip'] = (this.sector_page - 1) * this.sector_pageSize;

      params['pais_id'] = { $in: this.permisos_paises_asignados };

      if (this.searchSector) {
        params['$or'] = [
          {
            nombre: {
              $like: '%' + this.searchSector + '%',
            },
          },
          {
            region: {
              $like: '%' + this.searchSector + '%',
            },
          },
          {
            division: {
              $like: '%' + this.searchSector + '%',
            },
          },
          {
            pais: {
              $like: '%' + this.searchSector + '%',
            },
          },
        ];
      }

      if (
        this.sector_options.sortBy &&
        this.sector_options.sortBy.length === 1 &&
        this.sector_options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.sector_options.sortDesc[0] ? 1 : -1;
        sorting[this.sector_options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { nombre: 1 };
      }
      /**paginacion fin */

      return params;
    },
    sector_handlePageChange(value) {
      this.sector_page = value;
      this.mainSectores();
    },
    sector_handlePageSizeChange(size) {
      this.sector_pageSize = size;
      this.sector_page = 1;
      this.mainSectores();
    },
    /**paginacion fin */
    usuarios_getParams() {
      const params = {};
      /**paginacion */
      params['$limit'] = this.usuario_pageSize;
      params['$skip'] = (this.usuario_page - 1) * this.usuario_pageSize;
      params['status'] = 1;
      params['$or'] = [{
        rol_nombre: 'Administrador'
      },
      {
        rol_nombre: 'Administrativo'
      }]

      if (this.searchUsuario) {
        params['$or'] = [
          {
            nombre: {
              $like: '%' + this.searchUsuario + '%',
            },
          },
          {
            usuario: {
              $like: '%' + this.searchUsuario + '%',
            },
          },
          {
            rol_nombre: {
              $like: '%' + this.searchUsuario + '%',
            },
          },
        ];
      }

      if (
        this.usuario_options.sortBy &&
        this.usuario_options.sortBy.length === 1 &&
        this.usuario_options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.usuario_options.sortDesc[0] ? 1 : -1;
        sorting[this.usuario_options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { nombre: 1 };
      }
      /**paginacion fin */

      return params;
    },
    usuario_handlePageChange(value) {
      this.usuario_page = value;
      this.mainUsuarios();
    },
    usuario_handlePageSizeChange(size) {
      this.usuario_pageSize = size;
      this.usuario_page = 1;
      this.mainUsuarios();
    },
    /**paginacion fin */
    /** */
    getSelectedPermissions() {
      return this.listado_de_persmisos_para_modulos.flatMap((section) =>
        section.options
          .filter((option) => this.permisos_modulos.includes(option.value))
          .map((option) => ({
            section: section.title,
            label: option.label,
            value: option.value,
          }))
      );
    },
    getSelectedAsignaciones() {
      return this.permisos_usuarios_asignados.map((item) => ({
        id: item.id,
        pais: {
          id: item.pais_id,
          nombre: item.pais,
        },
        division: {
          id: item.division_id,
          nombre: item.division,
        },
        region: {
          id: item.region_id,
          nombre: item.region,
        },
        nombre: item.nombre,
        fechaCreacion: new Date(item.createdAt).toLocaleDateString(),
        fechaActualizacion: new Date(item.updatedAt).toLocaleDateString(),
      }));
    },
    /** */
    ...mapActions('UsuariosCambiosHistorico', {
      findUsuariosCambiosHistorico: 'find',
      getUsuariosCambiosHistorico: 'get',
      createUsuariosCambiosHistorico: 'create',
      patchUsuariosCambiosHistorico: 'patch',
    }),
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
      getUsuarios: 'get',
      createUsuarios: 'create',
      patchUsuarios: 'patch',
    }),
    ...mapActions('Paises', {
      findPaises: 'find',
      getPaises: 'get',
      createPaises: 'create',
      patchPaises: 'patch',
    }),
    ...mapActions('Sectores', {
      findSectores: 'find',
      getSectores: 'get',
      createSectores: 'create',
      patchSectores: 'patch',
    }),
    //función que se ejecuta cuando se da clic en el botón verde superior derecha para crear un nuevo usuario
    fnCrearNuevoUsuario() {
      console.log('creando nuevo usuario');
      this.flagEditar = false;
      this.dialogCrearUsuario = true;
    },

    //funcion que confirma que se va a crear un nuevo usuario, luego de haber seleccionado lo necesrio en el stepper
    fnCrearUsuarioConfirm() {
      console.log('nuevo usuario creado');
      let dataNewUser = {
        usuario: this.nuevoUsuario.usuario,
        nombre: this.nuevoUsuario.nombreYapellido,
        password: this.nuevoUsuario.password,
        rol_id: null,
        rol_nombre: null,
        //ESTADO: '',
        //STATUS: '',
        CREADA_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADA_POR_USUARIO_ID: this.currentUser.id,
        //EDITADO_POR_USUARIO_NOMBRE: '',
        //EDITADO_POR_USUARIO_ID: '',
        //EDITADO_FECHA: '',
        //DE_BAJA_USUARIO_ID: '',
        //DE_BAJA_USUARIO_NOMBRE: '',
        //DE_BAJA_FECHA: '',
        //MSJ_ULTIMO_CAMBIO: '',
        //VERSION: '',
        //id_pais: JSON.stringify(this.permisos_paises_asignados),
        //pais: JSON.stringify(this.permisos_paises_asignados),
        //id_division: '',
        //division: '',
        //id_region: '',
        //region: '',
        //id_sector: '',
        //sector: '',
        //dispositivo_marca: '',
        //dispositivo_modelo: '',
        //dispositivo_id: '',
        //dispositivo_reg_token: '',
        //fecha_vinculacion: '',
        //desvinculacion_usuario: '',
        //desvinculacion_usuario_id: '',
        //desvinculacion_motivo: '',
        //desvinculacion_fecha: '',
        permisos_modulos: JSON.stringify(this.permisos_modulos),
        permisos_paises_asignados: JSON.stringify(
          this.permisos_paises_asignados
        ),
        permisos_usuarios_asignados: JSON.stringify(
          this.permisos_usuarios_asignados
        ),
      };

      //rol del usuario
      if (this.switch_es_super_usuario == 1) {
        dataNewUser.rol_id = 1;
        dataNewUser.rol_nombre = 'Administrador';
      } else {
        dataNewUser.rol_id = 3;
        dataNewUser.rol_nombre = 'Administrativo';
      }
      //
      this.createUsuarios(dataNewUser)
        .then(() => {
          this.dialogCrearUsuario = false;
          this.$notify({
            title: 'Usuario Creado',
            text: `Se ha creado el usuario ${dataNewUser.usuario}`,
            color: '#4caf50',
            timeout: 3000,
          });
        })
        .catch((e) => {
          console.log(e);

          this.$notify({
            title: 'Error',
            text: e,
            color: '#D32F2F',
            timeout: 3000,
          });
        });
    },

    //-------------funciones para los botones de acciones:

    fnEditarUsuarioConfirm() {
      console.log('nuevo usuario editado');
      let REQUEST_BODY_TO_SEND = {
        usuario: this.nuevoUsuario.usuario,
        nombre: this.nuevoUsuario.nombreYapellido,
        password: this.nuevoUsuario.password,
        //rol_id: null,
        //rol_nombre: null,
        //ESTADO: '',
        //STATUS: '',
        //CREADA_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        //CREADA_POR_USUARIO_ID: this.currentUser.id,
        EDITADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        EDITADO_POR_USUARIO_ID: this.currentUser.id,
        EDITADO_FECHA: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
        //DE_BAJA_USUARIO_ID: '',
        //DE_BAJA_USUARIO_NOMBRE: '',
        //DE_BAJA_FECHA: '',
        MSJ_ULTIMO_CAMBIO: '',
        //VERSION: '',
        //id_pais: JSON.stringify(this.permisos_paises_asignados),
        //pais: JSON.stringify(this.permisos_paises_asignados),
        //id_division: '',
        //division: '',
        //id_region: '',
        //region: '',
        //id_sector: '',
        //sector: '',
        //dispositivo_marca: '',
        //dispositivo_modelo: '',
        //dispositivo_id: '',
        //dispositivo_reg_token: '',
        //fecha_vinculacion: '',
        //desvinculacion_usuario: '',
        //desvinculacion_usuario_id: '',
        //desvinculacion_motivo: '',
        //desvinculacion_fecha: '',
        permisos_modulos: JSON.stringify(this.permisos_modulos),
        permisos_paises_asignados: JSON.stringify(
          this.permisos_paises_asignados
        ),
        permisos_usuarios_asignados: JSON.stringify(
          this.permisos_usuarios_asignados
        ),
      };

      //cambios datos ingresodos vs usuario oroginal
      const detectar_cambios = this.compareObjects(
        this.prop_existing_user,
        REQUEST_BODY_TO_SEND,
        [
          'VERSION',
          'EDITADO_FECHA',
          'password',
          'EDITADO_POR_USUARIO_NOMBRE',
          'MSJ_ULTIMO_CAMBIO',
        ]
      );
      if (detectar_cambios) {
        this.createUsuariosCambiosHistorico({
          usuario_id: this.prop_existing_user.id,
          usuario_nombre: this.prop_existing_user.nombre,
          usuario: this.prop_existing_user.usuario,
          cambios: detectar_cambios,
          EDITADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
          EDITADO_POR_USUARIO_ID: this.currentUser.id,
          EDITADO_FECHA: moment()
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
        })
          .then(() => {
            console.log('historico creado');
          })
          .catch((e) => {
            console.log('error al crear  historico', e);
          });
        //
        REQUEST_BODY_TO_SEND.MSJ_ULTIMO_CAMBIO = detectar_cambios;
      }

      //version de usuario
      let version = null;
      if (this.prop_existing_user.VERSION === null) {
        version = 1;
      } else {
        console.log('aumentando version');
        version = this.prop_existing_user.VERSION + 1;
      }
      REQUEST_BODY_TO_SEND.VERSION = version;

      //rol del usuario
      if (this.switch_es_super_usuario == 1) {
        REQUEST_BODY_TO_SEND.rol_id = 1;
        REQUEST_BODY_TO_SEND.rol_nombre = 'Administrador';
      } else {
        REQUEST_BODY_TO_SEND.rol_id = 3;
        REQUEST_BODY_TO_SEND.rol_nombre = 'Administrativo';
      }
      //
      this.patchUsuarios([this.prop_existing_user.id, REQUEST_BODY_TO_SEND])
        .then(() => {
          this.dialogCrearUsuario = false;
          this.$notify({
            title: 'Usuario Creado',
            text: `Se ha editado el usuario ${REQUEST_BODY_TO_SEND.usuario}`,
            color: '#4caf50',
            timeout: 3000,
          });
        })
        .catch((e) => {
          console.log(e);

          this.$notify({
            title: 'Error',
            text: e,
            color: '#D32F2F',
            timeout: 3000,
          });
        });
    },

    //para dar de baja un usuario
    fnDarDeBaja(data) {
      console.log('eliminar usuario/registro');
      this.datosUsuarios = Object.assign(data);
      console.log(this.datosUsuarios);
      this.dialogDarDeBaja = true;
    },
    //funcion para confirmar que si se desea eliminar el usuario
    fnConfirmarDarDeBaja() {
      console.log(
        'se confirmó la eliminación/dar de baja al usuario seleccionado'
      );
      this.dialogDarDeBaja = false;
    },

    //para editar
    fnOpenDialog_EditarUsuario(item) {
      console.log('fnOpenDialog_EditarUsuario', item);
      //
      this.prop_existing_user = Object.assign(item);
      /**
       *
       */
      this.nuevoUsuario.nombreYapellido = item.nombre;
      this.nuevoUsuario.usuario = item.usuario;
      //
      if (item.rol_nombre == 'Administrador') {
        this.switch_es_super_usuario = true
      } else {
        this.switch_es_super_usuario = false
      }

      if (item.permisos_modulos) {
        this.permisos_modulos = JSON.parse(item.permisos_modulos);
      }
      //
      if (item.permisos_paises_asignados) {
        this.permisos_paises_asignados = JSON.parse(
          item.permisos_paises_asignados
        );
      }
      //
      if (item.permisos_usuarios_asignados) {
        this.permisos_usuarios_asignados = JSON.parse(
          item.permisos_usuarios_asignados
        );
      }
      /** */
      this.flagEditar = true;
      this.dialogCrearUsuario = true;
    },

    //funcion para ver el historial
    fnVerRegistros(item) {
      console.log('main - mostrar historico de usuario: ', item);

      (this.ver_log_de_usuario_id = item.id), (this.dialogHistorico = true);
    },

    //para cambiar de página en el stepper
    fnNextStep() {
      this.e1 = this.e1 + 1;
    },
    fnBackStep() {
      this.e1 = this.e1 - 1;
    },
    /**
     *
     */
    mainSectores() {
      this.findSectores({ query: this.sector_getParams() }).then((r) => {
        console.log(r.data);

        this.sectores = r.data;
        this.sector_totalRegistros = r.total;
        this.sector_totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.sector_pageSize)
        );
      });
    },
    /**
     *
     */
    mainUsuarios() {
      this.findUsuarios({ query: this.usuarios_getParams() }).then((r) => {
        this.tbl_usuarios = r.data;
        this.usuario_totalRegistros = r.total;
        this.usuario_totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.usuario_pageSize)
        );
      });
    },
  },
  async mounted() {
    this.findPaises({ query: { $limit: 500 } }).then((r) => {
      this.paises = r.data;
    });

    this.mainSectores();

    this.mainUsuarios();
  },
};
</script>

<style></style>
